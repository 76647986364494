import { MenuItemsListComponent } from './menu-items-list/menu-items-list.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import {MenuItemComponent} from './menu-item/menu-item.component';
import { CartComponent } from './cart/cart.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { AreaComponent } from './area/area.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { OrderSubmittedComponent } from './order-submitted/order-submitted.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ConfirmAddressComponent } from './confirm-address/confirm-address.component';
import { TermsComponent } from './terms/terms.component';
import { MapComponent } from './map/map.component';
import { HomeComponent } from './home/home.component';
import { AreaSelectionGuard } from './AreaSelectionGuard';
import { PrivacyComponent } from './privacy/privacy.component';
import { environment } from 'src/environments/environment';
import { TruckInfoComponent } from './truck-info/truck-info.component';
import { ServiceAreaComponent } from './service-area/service-area.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { PreCheckoutComponent } from './pre-checkout/pre-checkout.component';
import { SelectLocationComponent } from './select-location/select-location.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddressesComponent } from './addresses/addresses.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';


let routes: Routes = [
  {path:'', component:MenuComponent, canActivate:[AreaSelectionGuard]},
  {path:'item/:id',component:MenuItemComponent, canActivate:[AreaSelectionGuard]},
  {path: 'cart', component:CartComponent, canActivate:[AreaSelectionGuard]},
  {path:'checkout', component:CheckOutComponent, canActivate:[AreaSelectionGuard]},
  {path: 'area',component:AreaComponent, canActivate:[AreaSelectionGuard]},
  {path: 'track-order', component:TrackOrderComponent},
  {path: 'order/:id', component:OrderSubmittedComponent},
  {path:'about-us',component:AboutUsComponent},
  {path:'privacy',component:PrivacyComponent},
  {path:'categories/:catId/items',component:MenuItemsListComponent, canActivate:[AreaSelectionGuard]},
  {path:'confirm-address',component:ConfirmAddressComponent, canActivate:[AreaSelectionGuard]},
  {path:'terms',component:TermsComponent},
  {path:'sub-category/:id',component:SubCategoryComponent, canActivate:[AreaSelectionGuard]},
  {path:'menu-item',component:MenuItemsListComponent, canActivate:[AreaSelectionGuard]},
  {path:'map/:id',component:MapComponent},
  {path:'info',component:TruckInfoComponent},
  {path:'service',component:ServiceAreaComponent},
  {path:'catering-area',component:ServiceAreaComponent, data : {forCatering: true}},
  {path:'time-date',component:DateTimeComponent},
  {path:'pre-checkout',component:PreCheckoutComponent},
  {path:'set-location',component:SelectLocationComponent},
  {path:'home', component:environment.display_home ?HomeComponent : MenuComponent},
  {path: 'select-address', component:AddressesComponent, data:{type:'select'}},
  {path: 'my-addresses', component:AddressesComponent, data:{type:'list'}},

  {path: 'addresses/new', component:ConfirmAddressComponent, canActivate:[AreaSelectionGuard], data: { type: 'add' }},
  {path: 'addresses/:addressId/edit', component:ConfirmAddressComponent, canActivate:[AreaSelectionGuard], data: { type: 'edit' }},

  {path: 'my-orders', component:OrderHistoryComponent},
  {path: 'my-orders/:id', component:OrderDetailComponent},
  {path: 'order-details/:id', component:OrderDetailComponent, canActivate:[AreaSelectionGuard,]},

  {path: 'register', component:RegisterComponent},
  {path: 'login', component:LoginComponent},
  {path: 'forget-password', component:ForgetPasswordComponent},
  {path: 'reset-password', component:ResetPasswordComponent},
  
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

// if(environment.display_home){
//   routes.push({path:'home',component:HomeComponent});
// }
// //push the default route at end
// routes.push(  { path: '**', redirectTo: '', pathMatch: 'full' })

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
