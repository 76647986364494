import { GtagService } from './gtag/gtag.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { DefaultIntl } from './DatePickerProviders';
import { DeliveryTimeRes, LocalizedField } from './models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CartService } from './cart.service';
import { Cart } from './cart';
import { BranchSelectorComponent } from './branch-selector/branch-selector.component';
import { UtilsService } from './utils.service';
import { slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import * as _ from 'lodash'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInUpOnEnterAnimation({ delay: 1000 }), slideOutDownOnLeaveAnimation({ delay: 100 })]

})
export class AppComponent {
  logo: string = environment.logo;
  multiLang: boolean = false;
  allowAboutUs: boolean = false;
  isPreOrder: boolean = false;
  useDateOnly: boolean = false;
  deliveryTimes: DeliveryTimeRes;
  isPickupService: boolean = false;
  expectedDeliveryTime: any;
  expectedDeliveryTimeTo: any;
  preorder_note: LocalizedField;
  useMultiVendor: boolean;
  allowMultiVendor: boolean;
  branchId: number;
  showSoonestDeliveryTimeOnlyIfPreOrder: boolean;
  isIOS: boolean = false;
  addToHomeVisible: boolean = false
  @ViewChild(BranchSelectorComponent) branchSelector: BranchSelectorComponent;
  constructor(private gtag: GtagService,private utils: UtilsService, private translate: TranslateService, private modal: NgxSmartModalService, private api: ApiService, private srvCart: CartService) {
    this.gtag.login('init');
    let langs = [];
    this.isIOS = utils.isIOS;
    if (environment.default_lang)
      langs.push(environment.default_lang);
    if (environment.english && (environment.default_lang != 'en'))
      langs.push('en');
    if (environment.arabic && (environment.default_lang != 'ar'))
      langs.push('ar');
    $(document).on('click', d => {
      this.addToHomeVisible = false;

    });
    $(document).on('click', '.add-to-home-section', d => {
      d.stopPropagation()
      d.preventDefault();
    });

    if (langs.length > 1)
      this.multiLang = true;
      let path = _.lowerCase(location.pathname);
      console.log(path);
    if(!path || path == '/' || path == '/menu')
      this.addToHomeVisible = this.isIOS;
    translate.addLangs(langs)
    translate.setDefaultLang('en');
    let lang = localStorage.getItem('user_lang') || langs[0];
    this.toggleLang(lang);
    api.viewSBOptionsDetails().subscribe(res => {
      this.allowAboutUs = res.allowAboutUs;
      if (res.simpleBrokerOptions && res.simpleBrokerOptions.showSoonestDeliveryTime)
        this.getDeliveryTimes();
      this.allowMultiVendor = res.simpleBrokerOptions && res.simpleBrokerOptions.useMultiVendor;
      this.useMultiVendor = this.allowMultiVendor;
      this.showSoonestDeliveryTimeOnlyIfPreOrder = res.simpleBrokerOptions && res.simpleBrokerOptions.showSoonestDeliveryTimeOnlyIfPreOrder;
    })
    this.branchId = this.srvCart.getCart().orderInfo.branchId;
let cart = this.srvCart.getCart();
    if(cart.serviceType_ex == 'soonest_delivery'){
      cart.orderInfo.pickupTime = null;
      cart.orderInfo.pickupTimeTo =null;

      api.getDeliveryTimes(new Date(), 'delivery').subscribe(d=>{
        if(d.isAvailable && d.asSoonAsFrom){
          cart.orderInfo.pickupTime = d.asSoonAsFrom;
          if(d.asSoonAsTo != d.asSoonAsFrom)
            cart.orderInfo.pickupTimeTo = d.asSoonAsTo;
        }
      });
    }
  }
  onBranchesLoaded(branches) {
    if (branches && branches.length)
      this.useMultiVendor = this.allowMultiVendor && true;
  }
  toggleLang(forceLang?: string) {
    let lang = this.translate.currentLang == 'ar' ? 'en' : 'ar';
    if (forceLang)
      lang = forceLang;
    this.translate.use(lang);
    localStorage.setItem('user_lang', lang);
    $('.app-root').removeClass('lang-ar lang-en').addClass(`lang-${lang}`);
    DefaultIntl.langUpdated(this.translate);
  }
  closeSoonestTimeModal() {

    this.modal.getModal('mSoonestTime').close();
  }
  getDeliveryTimes() {
    let that = this;
    this.api.getDeliveryTimes(new Date(), 'delivery').subscribe(times => {
      this.deliveryTimes = times;
      this.useDateOnly = times.useDateOnlyForPreOrder;
      this.isPreOrder = times.isPreOrder;
      if (this.isPreOrder) {
        this.expectedDeliveryTime = times.asSoonAsFrom
        this.expectedDeliveryTimeTo = times.asSoonAsTo;

      } else {

        this.expectedDeliveryTime = times.asSoonAsFrom
        this.expectedDeliveryTimeTo = times.asSoonAsTo;
      }
      this.preorder_note = times.preOrderNote;

      console.log(this.expectedDeliveryTime, this.expectedDeliveryTimeTo);

      if (!this.showSoonestDeliveryTimeOnlyIfPreOrder || this.isPreOrder)
        this.modal.getModal('mSoonestTime').open();


    }, err => {

    });
  }
}
