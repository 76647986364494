import { Component, OnInit, ɵCompiler_compileModuleSync__POST_R3__, ViewEncapsulation, Output, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { Area, governate } from '../models';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash'
import { CartService } from '../cart.service';
import { environment } from 'src/environments/environment.prod-default';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-area',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})

export class AreaComponent implements OnInit {

  @Input()
  hideSelectLabel:boolean = false;
  @Input()
  saveToCart = true;
  @Input()
  areaObj:Area;
  @Output()
  areaObjChange = new EventEmitter<Area>();
  @Input()
  areaId:Number;
  @Output()
  areaIdChange = new EventEmitter<Number>();
  @Input()
  areaName:string;
  @Output()
  areaNameChange = new EventEmitter<string>();
  @Output()
  areaChanged = new EventEmitter<Area>();
  closeResult: string;
  isLoading:boolean = false
  areas=[];
  governates=[];
  selectedArea: Area;
  governate: governate;
  searchText: string;
  _oldArea:number
  results = [];
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService,private trans:TranslateService, private apiService : ApiService, private cartService: CartService) { }

  selectArea(cb?:()=>void) {
    this.isLoading = true;
    this.modal.getModal('mSelectArea').open();
    this.isLoading = false;

    this.nextCallback = cb;
  }

  ngOnInit() {
   var areaId = this.cartService.getCart().orderInfo.pickupAddress.areaId;
   let self = this;
   this.apiService.viewSBOptionsDetails().subscribe(t=>{
     this.apiService.getGovernate().subscribe((data)=>{
      let deleted = [21,44,45,46,48,53,55,65,69,185];
      _.each(data.items, gov=>{
        gov.areas = _.filter(gov.areas, a=> _.some(t.areas,
          function (ta){
            if(areaId == ta.area.id && a.id == ta.area.id){
              self.selectedArea = a;
              a.deliveryCharge = ta.deliveryCharge;
            }
            return !deleted.includes(a.id) && ta.area.id == a.id && ta.area.entityState == 'Active';
          }));
      })
      this.governates=data.items

     })
   })
   }

  onSave(area : Area){
    this.selectedArea=area
    if(this.saveToCart)
      this.cartService.setArea(area);

    this.areaId = area.id;//.emit(area.id );
    this.areaIdChange.emit(area.id );
    this.areaChanged.emit(area);
    this.areaName = this.trans.currentLang == 'ar' ? area.name.ar : area.name.en;
    this.areaNameChange.emit(this.areaName);
    this.areaObj = area;
    this.areaObjChange.emit(this.areaObj);
    this.modal.getModal('mSelectArea').close();
    if(this.selectedArea && this._oldArea != this.selectedArea.id && this.saveToCart)
      this.apiService.initTruckInfo();
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;

  }

  onGovernate(governate :governate){
    this.governate= governate
    governate.flag = !governate.flag

  }
}

