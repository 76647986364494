import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash'
import * as moment from 'moment'
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertResult } from 'sweetalert2'
import { Address, IWithLastknownStatus, IWithOperatingHours, IWithTruckStatus } from './models';
import { AskForMembershipComponent } from './ask-for-membership/ask-for-membership.component';

declare const snaptr: any
declare const fbq: any
declare const ttq: any

interface ITimeSpan {
  h: number,
  m: number,
  s: number,
  ha?: number,
  aen?: string,
  aar?: string,
}
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private stateChecked: boolean = false;
  constructor(private _srvLocation: Location,
    public toaster: ToastrService,
    private translate: TranslateService) {


    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      this.isMobile = true;
    if (/Android/i.test(ua))
      this.isAndroid = true;
    if (/iPhone|iPad|iPod/i.test(ua))
      this.isIOS = true;


  }
  private _tiktokInitialized = false;

  private _fbInitialized = false;
  private _snapchatInitialized = false;
  public isBackTriggered = false;
  public askForMembershipModal: AskForMembershipComponent
  isMobile: boolean = false;
  isIOS: boolean = false;
  isAndroid: boolean = false;
  isDesktop: boolean = false;
  setAskForMembershipModal(cmp: AskForMembershipComponent) {
    this.askForMembershipModal = cmp;
  }
  fbTrack(event: string, data: any = undefined) {
    if (!environment.fb_pixel_id)
      return;
    if (!this._fbInitialized) {
      fbq('init', environment.fb_pixel_id);
      this._fbInitialized = true;
    }
    if (environment.fb_pixel_id)
      fbq('track', event, data);
  }

  ttqTrack(event: string, data: any = undefined) {
    debugger;
    if (environment.tiktokPixle) {
        if (!this._fbInitialized) {
            ttq.load(environment.tiktokPixle);
            ttq.page()
            this._tiktokInitialized = true;
        }
        if (environment.tiktokPixle)
            ttq.track(event, data);
    }
}

  snapchatTrack(event: string, data: any = undefined) {
    if (!environment.snapchat_pixel_id)
      return;
    if (!this._snapchatInitialized) {
      snaptr('init', environment.snapchat_pixel_id,{ 'user_email': environment.user_email })
      this._snapchatInitialized = true;
    }
    if (environment.snapchat_pixel_id)
    {
      if(data)
      {snaptr('track', event,data);}
      else
    snaptr('track', event);
    }
  }

  getUrl(path) {
    let url = environment.domain;
    if (!url) {
      url = location.host
    }
    url = `${url}/${path}`;
    return url;
  }
  goBack() {
    this.isBackTriggered = true;
    let state: any = this._srvLocation.getState();
    if (state.navigationId <= 1)
      location.href = '/';
    else
      this._srvLocation.back();

  }
  parseTimeSpan(t: string): ITimeSpan {
    let parts = _.split(t, ':');
    let span: ITimeSpan = {
      h: parseInt(parts[0]),
      m: parseInt(parts[1]),
      s: parseInt(parts[2])
    }
    if (span.h == 0) {
      span.ha = 12
      span.aen = 'am'
    } else if (span.h > 12) {
      span.ha = span.h - 12
      span.aen = 'pm'
    } else if (span.h == 12) {
      span.ha = span.h
      span.aen = 'pm'
    } else {
      span.ha = span.h
      span.aen = 'am'
    }
    if (span.aen == 'am')
      span.aar = 'ص'
    else
      span.aar = 'م'
    return span;
  }
  isNowBetween(t1: string, t2: string, now: Date = null): boolean {
    if (!t1 || !t2)
      return true;
    now = now || new Date();
    return isOpen(t1, t2, now)
  }
  isTruckOpen(truck: IWithOperatingHours): boolean {
    if (truck.operatingFrom && truck.operatingTo)
      return this.isNowBetween(truck.operatingFrom, truck.operatingTo)

  }
  getTruckState(truck: IWithTruckStatus) {
    if (
      truck.lastKnowState != "busy" && (
        !this.isTruckOpen(truck) ||
        truck.lastKnowState == 'comingSoon'
        || truck.lastKnowState == 'offline'))
      return 'closed';
    return truck.lastKnowState;
  }
  isNavigationAllowed(truck: IWithLastknownStatus) {
    return true; //truck.lastKnowState != 'offline' && truck.lastKnowState != 'comingSoon'
  }

  error(msg, title = 'alert') {
    let code;
    if (msg) {
      if (msg.error) {
        if (msg.error.data)
          msg = msg.error.data;
        else
          msg = msg.error;
        code = msg.errorCode;
      }
      if (msg.errorDetail) {
        msg = msg.errorDetail;
        code = msg.code;
      }
      if (msg.errorMessage) {
        msg = msg.errorMessage;
        code = msg.code;
      }
      if (msg.message) {
        msg = msg.message;
        code = msg.code;
      }
    }
    if (code) {
      code = "err_" + code;
      let tmsg = this.translate.instant(code);
      if (tmsg !== code) {
        msg = code
      }
    }
    return this.notify('warning', title, msg)
  }
  warn(msg, title = 'warning') {
    return this.notify('warning', title, msg)

  }
  CheckStateAndContinue(truckState, callBack) {
    const confirmButtonText = this.translate.instant('Continue browse products');
    const cancelButtonText = this.translate.instant('Continue');
    const title = this.translate.instant('Alert');
    let canAddToCart = false;
    let msg;
    switch (truckState) {
      case 'busy':
        msg = this.translate.instant("Site is busy. you can't order right now, you can add products and order later");
        canAddToCart = true;
        break;
      case 'offline':
      case 'comingSoon':
        msg = this.translate.instant("Ordering from website is not available right now. You can browse products and order later");
        break;
    }
    if (!msg) {
      callBack(true);
    } else {
      if (!this.stateChecked) {
        this.stateChecked = true;
        Swal.fire({
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          allowEscapeKey: true,
          allowOutsideClick: true,
          titleText: title,
          text: msg,
          confirmButtonColor: "rgb(106, 162, 119)",//"#dc3545",
          cancelButtonColor: 'rgb(220, 97, 108)',// '#28a745',
          focusCancel: false,
          focusConfirm: false,
          reverseButtons: this.translate.currentLang == 'ar',
        }).then(d => {
          callBack(canAddToCart);
        });
      } else {
        callBack(canAddToCart);
      }
    }


  }
  confirm(msg, title = 'confirm') {


    if (!title)
      title = this.translate.instant('Are you sure?');
    else
      title = this.translate.instant(title)

    if (msg)
      msg = this.translate.instant(msg)

    const confirmButtonText = this.translate.instant('Yes');
    const cancelButtonText = this.translate.instant('Cancel');

    return Swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      allowEscapeKey: true,
      allowOutsideClick: true,
      titleText: title,
      text: msg,
      confirmButtonColor: "rgb(106, 162, 119)",//"#dc3545",
      cancelButtonColor: 'rgb(220, 97, 108)',// '#28a745',
      focusCancel: false,
      focusConfirm: false,
      reverseButtons: this.translate.currentLang == 'ar',
    });

  }
  success(msg, title = 'warning') {
    return this.notify('success', title, msg)

  }
  notify(type, title, msg) {
    if (!title)
      title = type;
    title = this.translate.instant(title)
    if (!msg)
      msg = type
    msg = this.translate.instant(msg)

    const confirmButtonText = this.translate.instant('OK');
    console.log(title);
    return Swal.fire({
      icon: type,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      allowEscapeKey: true,
      allowOutsideClick: false,
      titleText: title,
      text: msg,
      confirmButtonColor: "rgb(106, 162, 119)",//"#dc3545",
      cancelButtonColor: 'rgb(220, 97, 108)',// '#28a745',
      focusCancel: false,
      focusConfirm: false,
      reverseButtons: this.translate.currentLang == 'ar'
    });
  }
  public scrollToError() {
    let el = $('.ng-invalid')[0];
    if (el)
      el.scrollIntoView({
        behavior: 'smooth'
      });
  }
  setAddressFromGeocoder(result: google.maps.GeocoderResult[], address: Address) {
    if (result && result[0]) {
      //block
      let cmpBlock = _.find(result, r => _.includes(r.types, "neighborhood"));
      if (cmpBlock) {
        let blockNo = _.find(cmpBlock.address_components, c => _.includes(c.types, "neighborhood"));
        if (blockNo) {
          let parsed = _.trim(_.replace(_.lowerCase(blockNo.short_name), "block", ""));
          address.blockNo = parsed;
        }
      }
      address.street = '';
      //street
      let cmpStreet = _.find(result,
        r => !!_.find(r.address_components, c => _.includes(c.types, "street_number") || _.includes(c.types, "route")));
      if (cmpStreet) {
        let street = _.find(cmpStreet.address_components, c => _.includes(c.types, "street_number") || _.includes(c.types, "route"));
        if (street) {
          let parsed = _.trim(_.replace(street.long_name, /[Unnm|Unnamed|street|Street|St|st|road|Road|rd|Rd]/g, ""));
          address.street = parsed;
        }
      }
      address.addressLine1 = result[0].formatted_address;
    }
    //area
    //country
  }
}

function isOpen(open, close, now) {
  if (open == close)
    return true;
  // Check today's opening hours
  if (checkOpenHours(now, now, open, close)) {
    return true;
  }
  var yesterday_date = new Date(now.getFullYear(), now.getMonth(), (now.getDate() - 1), 0, 0, 0, 0);
  if (checkOpenHours(now, yesterday_date, open, close)) {
    return true;
  }

  // Not open
  return false;
}

/*
  Check if "now" is within operating hours
*/
function checkOpenHours(now, operatingDate, open, close) {
  // Splitting times to array
  var o = open.split(":");
  var c = close.split(":");

  // Hours not in proper format
  if (o.length < 2 || c.length < 2) {
    return false;
  }

  // Converting array values to int
  for (var i = 0; i < o.length; i++) {
    o[i] = parseInt(o[i]);
  }
  for (var i = 0; i < c.length; i++) {
    c[i] = parseInt(c[i]);
  }

  // Set opening Date()
  var od = new Date(operatingDate.getFullYear(), operatingDate.getMonth(), operatingDate.getDate(), o[0], o[1], 0, 0);

  // Set closing Date()
  var closingDay = operatingDate.getDate();

  // Closing after midnight, shift day to tomorrow
  if (o[0] > c[0]) {
    closingDay++;
  }
  var cd = new Date(operatingDate.getFullYear(), operatingDate.getMonth(), closingDay, c[0], c[1], 0, 0);

  // Is within operating hours?
  if (now > od && now < cd) {
    return true;
  }
  else return false;
}
