<div class=" d-flex flex-column c-pointer align-items-space-between inline-item-card ">
    <div class="item-pic cat-img" (click)="goToItemDetails(item)">
        <img class="item-img-inline" [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt="">
    </div>
    <div class="item-info  flex-column align-items-between justify-content-between">
        <h3 routerLink='/item/{{item?.itemInfo.id}}'>{{item?.itemInfo.title | iknLocalized}}</h3>
        <div class="item-description">
            <div class="color-grey content-part" (click)="goToItemDetails(item)">
                {{ formatContent(item?.itemInfo.ingredients | iknLocalized) }}

            </div>

            <p class="read-mr-ls" *ngIf="conentLength > limit">
                <span *ngIf="!isContentToggled" (click)="toggleContent()">{{'ReadMore' | translate}}</span>
                <span *ngIf="isContentToggled" (click)="toggleContent()">{{'ReadLess' | translate}}</span>
            </p>
        </div>

        <div class="d-flex align-items-center justify-content-between price-btn">
            <div class="font-bold mt-1 price-item" (click)="goToItemDetails(item)">
                <div *ngIf="item?.itemInfo.price">
                    {{item.itemInfo.price | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}
                </div>
                <div *ngIf="!item?.itemInfo?.price" class='t-medium'>
                    {{'Price on selection' | translate}}
                </div>
            </div>
            <div class="btn btn-outline-secondary p-0 position-relative cart-btn" (click)="addItem(item)">
                <span class="text-black btn-text-cart">{{'Add To Cart' | translate}}</span>
                <div class="add-to-cart-plus-icon">+</div>
            </div>
        </div>
    </div>

</div>
<!-- <hr class="m-2"> -->
<div class="menu-card d-none">
    <a (click)="goToItemDetails(item)">
        <div class="img"><img [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt=""></div>
        <div class="card-title f-s-18 text-center">{{item?.itemInfo?.title | iknLocalized}}</div>
    </a>
    <div class="row-nums">
        <div class="desc text-danger out-stock-txt respect-dir" *ngIf="checkOutOfStock(item)">
            {{'Out of Stock'| translate}}
        </div>
        <div class="force-rtl" style="display:flex;" *ngIf="!checkOutOfStock(item)">
            <span class="bg-primary plus-icon" (click)="addItem(item)"><i class="im im-plus"></i></span>
            <span class="num-qty respect-dir">
                <span class="card-price font-bold">
                    {{item.itemInfo.price | iknCurrency}}
                    {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}
                </span>
                <span class="card-qty">&nbsp;<i class="fa fa-times"></i> {{item.qty}}</span>
            </span>
            <span class="bg-primary minus-icon" (click)="removeItem(item)"><i class="im im-minus"></i></span>
        </div>
    </div>
</div>