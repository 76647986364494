<app-top-header class="top-bar-container" [bgStyle]="'solid'" [screenTitle]='(item?.itemInfo?.title | iknLocalized) || " "'></app-top-header>
<div class="product-block" *ngIf="item">
    <div class="product-img">
        <ngx-slick-carousel (init)="sliderInit($event)" (afterChange)="afterChange($event)" *ngIf="item?.itemInfo?.itemPics?.length" class="carousel" #slickModal="slick-carousel"
            [config]="slideConfig">
            <div ngxSlickItem *ngFor="let itemPic of item?.itemInfo.itemPics" class="slide">
                <img [src]="itemPic.pic || 'assets/images/logo.png'" alt="">
            </div>
        </ngx-slick-carousel>

        <img *ngIf="!item?.itemInfo?.itemPics?.length" [src]="item?.itemInfo.mainPic || 'assets/images/logo.png'"
            alt="">
    </div>
</div>
<div class="page-content" *ngIf="item">
    <div iknLoader [isActive]='!item' class="loader-container"></div>
    <div class="item-dsc-part">
    <div class="py-2 px-0">
        <h6 >{{item?.itemInfo?.notes | iknLocalized}}</h6>
        <div class="position-relative d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column align-items-center justify-content-center">
                <h6 class=" item-heading " >{{'Quantity' | translate}}</h6>
                <div class="d-flex align-items-center justify-content-between border rounded" style='min-height: 1.5rem;'>
                    <div class="px-2" (click)="addQty()">
                        <h6 class="fa fa-plus m-0"></h6>
                    </div>
                    <div class="px-2 f-boldest">{{qty}}</div>
                    <div class="px-2" (click)="qty= qty >1 ? qty-1 : qty">
                        <h6 class="fa fa-minus m-0"></h6>
                    </div>
                </div>
            </div>
            <div class="position-absolute" style="top:4rem;pointer-events: none">
                <div class="d-flex align-items-center justify-content-end" style="pointer-events: none">
                    <div class="price-before"
                        *ngIf="item?.itemInfo?.priceBefore != null && item?.itemInfo?.priceBefore > 0">
                        {{item.itemInfo.priceBefore | iknCurrency}}
                        {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                    <div class="price ">{{item.itemInfo.price | iknCurrency}}
                        {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
                </div>
            </div>

         
        </div>
        <div style="display: flex;position: relative;">
                <div>
                        <div *ngIf="item.previousItemId" class="right-arrow">
                            <a  class="carousel-control left" href='/item/{{item.previousItemId}}'>
                                <span class="far fa-arrow-left"></span>
                            </a>
                        </div>
                        <p class="next-text">{{'prev-item' | translate}}</p>
                </div>
                 <div >
           
                    <div  *ngIf="item.nextItemId"  class="left-arrow">
                        <a class="carousel-control right" href='/item/{{item.nextItemId}}'>
                            <span class="far fa-arrow-right"></span>
                        </a>
                    </div>
                    <p class="prev-text">{{'next-item' | translate}}</p>
      
                 </div>

        </div>
        <!-- <hr class="m-0 p-0 my-1" *ngIf='item.itemInfo.setupTime'> -->
        <!-- <div class="mt-2" *ngIf='item.itemInfo.setupTime'>
            <div class="d-flex align-items-center">
                <h6 class="m-0 mx-1">{{'Preparation Time' | translate}}: </h6>
                <div>
                    {{item.itemInfo.setupTime | iknMinutes}}
                </div>
            </div>
        </div> -->
        <div class="mt-2" *ngFor="let sec of item.itemInfo.detailSections">
            <div class="d-flex"
                [class]="{'flex-column': sec.type != 'SingleLine', 'align-items-center': sec.type == 'SingleLine'}">
                <h6 class="m-0 mx-1">{{sec.title | iknLocalized}}: </h6>
                <div *ngIf="sec.type != 'Html'" class="overflow-hidden w-100">
                    {{sec.details | iknLocalized}}
                </div>
                <div *ngIf="sec.type == 'Html'">
                    <div [innerHTML]="sec.details | iknLocalized | iknHtml"></div>
                </div>
            </div>
        </div>
        <!-- <hr class="m-0 p-0 my-1"> -->

    <div class="Item-descibtion-margin">
            <div class="item-dsc-part1"  >
                <h6 class=" item-heading ">{{'Description' | iknLocalized}}</h6>
                {{item?.itemInfo.ingredients | iknLocalized}}
            </div>
        <!-- <hr class="m-0 p-0 my-1"> -->
        
        <div class="item-dsc-part1" *ngIf="item.extras && item.extras.length">
            <h6 class="item-heading">{{'Extras' | translate}}</h6>

            <div *ngFor="let ecat of item.extras">

                <div class="d-flex justify-content-between align-items-center">
                    <h6 class="m-0 font-extraBold">{{ecat.extraCategory.title | iknLocalized}}</h6>
                    <h6 class="m-0 color-grey" *ngIf="ecat.extraCategory.minQty">
                        {{'Choose' | translate}} {{ecat.extraCategory.minQty}}
                    </h6>
                </div>
                <!-- <hr class="m-0 my-1"> -->
                <div class="d-flex align-items-center justify-content-between extraimage-section mb-2"
                    *ngFor='let extra of ecat.extraItems'>
                    <div style="min-width: 100px;min-height: 70px; width:100px;height:70px;border-radius: 5px;">
                        <img [src]="extra.mainPic || item.itemInfo.mainPic" style="width:100%;height:100%;border-radius: 5px;">
                    </div>
                    <div class="d-flex align-items-center justify-content-between border rounded mx-1">
                        <div class="" (click)="addExtraItem(extra, ecat)"
                            style="width:2rem;height:1.5rem;line-height: 1.5rem;;text-align: center">
                            <h6 class="fa fa-plus m-0"></h6>
                        </div>
                        <h6 class=" m-0" style="width:2rem;height:1.5rem;line-height: 1.5rem;text-align: center;">
                            {{extra.qty}}</h6>
                        <div class="" (click)="removeExtraItem(extra)"
                            style="width:2rem;height:1.5rem;line-height: 1.5rem;;text-align: center">
                            <h6 class="fa fa-minus  m-0"></h6>
                        </div>
                    </div>
                    <div class="flex-grow-1 px-2">
                         {{extra.title | iknLocalized}} 
                    </div>
                    <div>
                        <span class="text-danger out-stock-txt font-extraBold" *ngIf="checkExtraOutOfStock(extra)">
                            {{'Out of Stock'| translate}}
                        </span>
                        <span *ngIf="!checkExtraOutOfStock(extra)">
                            {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                            {{extra.price | iknCurrency}}
                        </span>

                    </div>

                </div>
            </div>
        </div>

        <div class="product-special-note font-extraBold mt-2 item-dsc-part1">
            <h6 class='t-large t-boldest'>{{'Add a note' | translate}}</h6>
            <div class="textarea-wrapper">
                <!-- <i class="im im-mail"></i> -->
                <textarea [(ngModel)]="notes" class='autoExpand' rows='1' data-min-rows='1'
                    placeholder="{{'Add a note'| translate}}"></textarea>
            </div>
        </div>
    </div>

    </div>

</div>
</div>
<div class="page-btn f-booldest t-small" *ngIf="!isLoading" [@slideInUpOnEnter]>
    <div class='d-flex'>
    <a class="btn btn-primary bg-primary pull-heading-right btn-cart  f-booldest t-medium"
    style='flex:2'
        (click)="!isOutOfStock && addToCart()" [class.disabled]="isOutOfStock">
        <div class='d-flex justify-content-center align-items-center'>
            <div class='' >
                {{'ADD TO CART' | translate}}
            </div>
            <div class='mx-1'></div>
            <div class="">
                <div>{{calculatedPrice | iknCurrency}}
                    {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
            </div>
        </div>
    </a>
    <div class='mx-2'></div>
    <a class="btn btn-primary bg-primary pull-heading-right btn-cart  f-booldest t-medium"
    style='flex:1'
        (click)="addToCart(true)" [class.disabled]="isOutOfStock">
        
            <div>
                {{'CHECKOUT' | translate}}
            </div>
        
    </a>
    
</div>
  
</div>
<!--PIXEL META DATA-->
<meta property="og:title" content="{{item?.itemInfo.title.en}}">
<meta property="og:description" content="{{item?.itemInfo.ingredients.en}}">
<meta property="og:url" content="{{pageUrl}}">
<meta property="og:image" content="{{item?.itemInfo.mainPic}}">
<meta property="product:price:amount" content="{{item?.itemInfo?.price}}">
<meta property="product:price:currency" content="KWD">
<meta property="product:retailer_item_id" content="{{item?.itemInfo?.id}}">
<meta property="product:availability" content="in stock">
<app-footer></app-footer>